
 .sectionCenter-cart{
  display: flex;
  justify-content: center;
  align-items: center;
 
 }

 a{
  text-decoration: none;
 }

 .sectionCenterNotify-cart{
text-align: center;
 }

 .container-cart {
  max-width: 1440px;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.heading {
  font-size: 28px;
  font-weight: 600;
  color: hsl(0, 0%, 25%);
  border-bottom: 1px solid hsl(0, 0%, 90%);
  padding: 20px 60px;
  display:     flex;
  align-items: center;
  gap: 20px;
}
.item-flex {
  display: flex;
  justify-content: center; /* Centra horizontalmente */

}

  
   @media (max-width: 1200px) {
  
    .item-flex { flex-direction: column-reverse; }
    .img-notify-cart{
      width: 300px;
      height: 300px;
    }
    .sectionCenterNotify-cart{
      text-align: center;
      margin-bottom: 100px;
     }
    

   }
  
  
  
  

a{
    text-decoration: none;
}

.cardStyle-item {
    width: 300px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    background: hsl(0, 100%, 100%);
    border: 1px solid hsl(0, 0%, 93%);
    position: relative;
    transition: all 300ms;
}
.sold-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .reserved-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 251, 0, 0.7);
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
  }
.cardStyle-item:hover {
    transform: scale(1.015);
}

.imageOfCard-item {
    overflow: hidden;
    justify-content: center;
    width: 200px;
    height: 200px;
    margin: 50px;
    text-align: center;
}

.infoOfCard-item {
    margin: 5px 20px 0;
}

.infoOfCard-item h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

.offerOfCard-item {
    position: absolute;
    top: 10px;
    left: 15px;
    background: hsl(198, 56%, 66%);
    font-size: 0.90rem;
    font-weight: 500;
    color: hsl(0, 100%, 100%);
    padding: 0 8px;
    border-radius: 5px;
    z-index: 3;


}

.categoryOfCard-item {
    color: hsl(198, 56%, 66%);
    font-size: 0.80rem;
    text-transform: uppercase;
    margin-bottom: 10px;

}

.nameOfCard-item {

    color: hsl(0, 0%, 47%);
    font-size: 0.95rem;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 5px;
    transition: 0.2s ease;
}

.detailButtonCard {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 10px;

}

.ratingOfCard-item {
    color: hsl(29, 90%, 65%);
    margin-bottom: 5px;
    font-size: 1rem;
}





.nameOfCard-item:hover {
    color: hsl(0, 0%, 13%);
}



.priceBox-item {
    display: flex;
    gap: 10px;
    font-size: 1.0rem;
    margin-bottom: 10px;
    color: hsl(0, 0%, 13%);
}

.priceOfCard-item {
    font-weight: 700;
}

.priceBox-item del {
    color: hsl(0, 0%, 47%);
}


@media(max-width: 750px) {

    .cardStyle-item {
        width: 300px;
    }
}
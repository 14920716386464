html,
body {
    font-family: 'Roboto', sans-serif;
}


.img-detail {
    width: 100%;
    height: auto;
    display: block;
  }



.section-center-detail {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-detail {
    max-width: 1200px;
    padding: 0 1rem;
    margin: 0 auto;
    border-radius: 10px;
    background: hsl(0, 100%, 100%);
    border: 1px solid hsl(0, 0%, 93%);
}


.section-center-circular {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50%;
    font-family: 'Trebuchet MS', sans-serif;


}


.product-div {
    margin: 1rem 0;
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #fff;
    border-radius: 3px;
    column-gap: 10px;
}

.product-div-right,
.product-div-left {
    max-width: 100%; /* Asegura que el ancho máximo sea siempre 100% del contenedor padre */
    box-sizing: border-box; /* Incluye padding y border en el cálculo del ancho */
}

.img-container {
    width: 100%;
    max-width: 600px; /* O el tamaño que prefieras */
    margin: 0 auto;
    overflow: visible; /* Cambia a visible si es necesario */
  }

.product-div-right span {
    display: block;
}

.product-name {
    font-size: 26px;
    margin-bottom: 22px;
    font-weight: 700;
    letter-spacing: 1px;
    opacity: 0.9;
}

.product-link {
    color: #007bff;
    text-decoration: underline;
    display: block;
    word-break: break-word;
}
.product-price {
    font-size: 24px;
    color: #ff4500; 
    font-weight: bold;
    display: block;
     margin-bottom: 20px;
    
}

.product-rating {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.product-rating span {

    margin-bottom: 5px;
}

.color-star-rating {
    color: hsl(29, 90%, 65%);
    margin-bottom: 5px;
    font-size: 1rem;
}

.product-rating span {
    margin-right: 6px;
}

.product-description {
    font-weight: 18px;
    line-height: 1.6;
    font-weight: 300;
    opacity: 0.9;
    margin-top: 22px;
}

.item-action {
    margin-top: 22px;
}

.item-action button {
    display: inline-block;
    font-size: 16px;
    font-family: inherit;
    text-transform: uppercase;
    padding: 15px 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.add-cart-btn {
    border: 1px solid rgb(0, 0, 0);
    ;
    border-radius: 5px;
    background: hsl(0, 100%, 100%);
    cursor: pointer;
    color: rgb(0, 0, 0);
}

.add-cart-btn:hover {
    background: hsl(0, 0%, 93%);

}


.buy-btn {
    border-radius: 5px;
    background: hsl(198, 56%, 66%);
    border: 1px solid hsl(198, 56%, 66%);
    cursor: pointer;
    transition: all .5s;
    color: white;
    margin-left: 5px;
}



.buy-btn:hover {
    background: rgb(50, 154, 240);
}

@media screen and (max-width: 992px) {
    .product-div {
        grid-template-columns: 100%;
    }

    .product-div-right{
        max-width: 90%; /* Ajusta el ancho máximo para pantallas pequeñas */
        margin: 0 auto; /* Centra el contenedor horizontalmente */

    }
    .product-div-left {
        max-width: 400px; /* Ajusta el ancho máximo para pantallas pequeñas */
        margin: 0 auto; /* Centra el contenedor horizontalmente */
    }

    .product-rating {
        justify-content: center;
    }

    .product-description {
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;
    }

    .add-cart-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .buy-btn {
        width: 100%;
        margin-left: 0px;
    }
}

@media screen and (max-width: 400px) {
    .item-action button {
        width: 100%;
        margin-bottom: 10px;
    }
    .product-div-right{
        max-width: 60%; /* Permite que el contenedor use todo el ancho disponible */
        padding: 0 10px; /* Añade un poco de padding para evitar que el contenido toque los bordes */
    }
    .product-div-left {
        max-width: 300px; /* Permite que el contenedor use todo el ancho disponible */
        padding: 0 10px; /* Añade un poco de padding para evitar que el contenido toque los bordes */
    }
}
.cart-detail {
    width: 40%;
    display:         flex;
    flex-direction:  column;
    
  }

  .btn-detail{
    border-radius: 5px;
    width: 100%;
    margin-left: 20px;
    margin-top: 50px;
    border: none;
    background: none;
    font: inherit;
    cursor: pointer;
    background: hsl(7, 64%, 55%);
    font-weight: 500;
    color: hsl(0, 0%, 100%);
    padding: 13px 45px;
    transition: 0.2s;
  }

  .btn-detail-reserved{
    border-radius: 5px;
    width: 100%;
    margin-left: 20px;
    margin-top: 50px;
    border: none;
    background: none;
    font: inherit;
    cursor: pointer;
    background: blue;
    font-weight: 500;
    color: hsl(0, 0%, 100%);
    padding: 13px 45px;
    transition: 0.2s;
  }

  .btn-detail:hover {

   background:hsl(0, 0%, 100%);
   color: hsl(7, 64%, 55%);
   border: 1px solid hsl(7, 64%, 55%);
  }
  .btn-detail-reserved:hover {

    background:hsl(0, 0%, 100%);
    color: blue;
    border: 1px solid blue;
   }
 
  



  .heading-cart-detail {
    color: hsl(0, 0%, 25%);
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
  }
  
  
  
  .cart-box-detail {
    padding: 40px 60px;
    margin-bottom: 30px;
  }
  
  
  
  .product-card .card1 {
    position: relative;
    display:     flex;
    align-items: flex-start;
    gap: 20px;
    
 
  }
  
  
  
  .card1 .detail .product-name {
    font-weight: 600;
    font-size: 15px;
    color: hsl(0,0%, 39%);
    margin-bottom: 10px;
  }
  
  .card1 .detail .wrapper {
    display: flex;
    gap: 20px;
  }
  
  .product-qty {
    display:     flex;
    align-items: center;
    gap: 10px;
  }
  

  

  
  .product-close-btn {
    position: absolute;
    top:   0;
    right: -20px;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  
 




  .amount {
     padding: 40px 60px; 
     border-top: 1px solid hsl(0, 0%, 90%);

    }
  
  .amount > div {
    display:         flex;
    justify-content: space-between;
  }
  
  .amount > div:not(:last-child) { margin-bottom: 10px; }
  
  .amount .total {
    font-size: 18px;
    font-weight: 700;
    color: hsl(0, 0%, 25%);
  }

  
  
  
  

  
  @media (max-width: 1200px) {
  

  

    .btn-detail{
   
     
      margin-left: 0px;

    }
  
  
    .cart-detail{
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      border-bottom: 1px solid hsl(0, 0%, 90%);
    }
  
    .cart-detail .wrapper { margin-top: auto; }
  
    .cart-detail .cart-box-detail {
      border-right: 1px solid hsl(0, 0%, 90%);
      margin-bottom: 0;
    }
  

  
  }
  
  
  
  
  
  @media (max-width: 768px) {
  
    
  
    .cart-detail { grid-template-columns: 1fr; }
  

  

  
  }
  
  
  
  

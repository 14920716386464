* {
    margin: 0;
    padding: 0;

    scroll-behavior: smooth;
}

.header-navbar {
    background-color: hsl(0, 100%, 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
    border-bottom: 1px solid hsl(0, 0%, 93%);
    -webkit-box-shadow: 0px 0px 1px 0px rgba(133, 133, 133, 0.34);
    -moz-box-shadow: 0px 0px 1px 0px rgba(133, 133, 133, 0.34);
    box-shadow: 0px 0px 1px 0px rgba(133, 133, 133, 0.34);
}

#logo {
    padding-left: 120px;
}

.header-navbar img {
    margin-left: 50px;
    width: 165px;

}

.submenu-navbar li {
    display: inline;
    list-style: none;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: large;
}

#home {
    display: none;
}

.header-navbar .link-navbar {
    margin: 10px 10px;
    text-decoration: none;
    color: hsl(0, 0%, 27%);
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 15px 0;
    transition: 0.2s ease;
}

.header-navbar a {
    text-decoration: none;
    color: #454545;

}

.iconQuantity-navbar {
    display: flex;
    padding-right: 120px;
}

.header-navbar .iconQuantity-navbar h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 50%;
    width: 17px;
    outline: none;
    border: none;
    position: absolute;
    color: white;

}

.iconQuantity-navbar-none {
    visibility: hidden;

}



.header-navbar a.active {
    text-decoration: none;
    color: hsl(198, 56%, 66%);
}


.header-navbar .link-navbar:hover {
    color: hsl(198, 56%, 66%);
}

.header-navbar label {
    display: none;
    margin-left: 50px;
}

.header-navbar input {
    display: none;
}


@media(max-width: 1100px) {

    .header-navbar {
        justify-content: space-between;
    }

    .submenu-navbar {

        position: absolute;
        width: 100%;
        height: 20vh;
        background: hsl(0, 100%, 100%);
        top: 50px;
        left: -150%;
        text-align: center;
        transition: all .5s;
        border-radius: 8px;
        box-shadow:
            0 10px 10px rgba(0, 0, 0, 0.22);
        z-index: 4;
    }

    #logo {
        display: none;
    }

    #home {
        display: block
    }


    .header-navbar label {
        display: block;
        float: inline-start;
    }

    .header-navbar a {
        margin: none;
    }

    .submenu-navbar li {
        display: list-item;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .logo-navbar {
        display: none;
    }




    .iconQuantity-navbar {
        padding-right: 0;
    }

    #check:checked+.submenu-navbar {
        left: 0%;

    }
}
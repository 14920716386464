.section-checkout {
  width: none;
  padding: 40px 60px;
  background: hsl(0, 0%, 100%);
  border-right: 1px solid hsl(0, 0%, 90%);
}

.heading-checkout {
  color: hsl(0, 0%, 25%);
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
}

.form-cart {
  margin-bottom: 40px;
}

.form-method {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
}

.form-method .method {
  border: 1px solid hsl(0, 0%, 64%);
  border-radius: 5px;
  width: 50%;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  gap: 20px;
  cursor: pointer;
}

.form-method .selected {
  border-color: hsl(219, 77%, 60%);
}

.button-payment-cart {
  border: none;
  background: none;
  font: inherit;
  cursor: pointer;
}

.label-cart {
  padding-left: 10px;
  margin-bottom: 5px;
  font-size: 14px;
  color: hsl(0, 0%, 64%);
}

.input-cart {

  width: 100%;
  margin: 6px 0px;
  height: 25px;
  border-radius: 5px;
  background: hsl(0, 0%, 91%);
  color: hsl(0, 0%, 30%);
  padding: 10px 0px;
  font-size: 18px;
  font-weight: 500;
  text-indent: 5px;

}


.div-input-cart {
  margin-bottom: 20px;
}

.card-number .input-cart,
.cvv .input-cart {
  letter-spacing: 3px;
}

.inputFlex-checkout {
  display: flex;
  align-items: center;
  gap: 30px;
}

.inputFlex-checkout .expire-date,
.inputFlex-checkout .cvv {
  width: 50%;
}

.expire-date .inputFlex-checkout {
  color: hsl(0, 0%, 62%);
  gap: 13px;
}

.expire-date .inputFlex-checkout .input-cart {
  text-align: center;
}

.btn {
  border-radius: 5px;
}

.btn:active {
  transform: scale(0.99);
}

.btn:focus {
  color: hsl(0, 0%, 100%);
  background: hsl(219, 77%, 60%);
  outline: 2px solid hsl(219, 77%, 60%);
  outline-offset: 2px;
}

.btn-primary {
  background: hsl(219, 77%, 60%);
  font-weight: 500;
  color: hsl(0, 0%, 100%);
  padding: 13px 45px;
}

.btn-primary b {
  margin-right: 10px;
}
.inputCart {
  font: inherit;
  width: 100%;
  border: none;
}

.inputCart:focus { outline: 2px solid hsl(219, 77%, 60%); }
@media (max-width: 1200px) {

  .item-flex {
    flex-direction: column-reverse;
  }


  .checkout {

    border-right: none;
  }

  .btn-primary {
    width: 100%;
  }

  .section-checkout {

    border-right: none;
  }

}

@media (max-width: 768px) {


  .wrapper-flex {
    gap: 20px;
  }

}





@media (max-width: 567px) {

  :root {
    --px: 20px;
  }

  .form-method,
  .inputFlex-checkout {
    flex-direction: column;
    gap: 20px;
  }

  .form-method .method {
    width: 100%;
  }

  .inputFlex-checkout .expire-date,
  .inputFlex-checkout .cvv {
    width: 100%;
  }

  .expire-date .inputFlex-checkout {
    flex-direction: row;
  }

}
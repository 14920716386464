.sectionCenterItemList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'Trebuchet MS', sans-serif;
    position: relative;

}

.circular-progress {
    margin-bottom: 60%;
}

.banner {
  background-color: #f8f8f8; 
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  width: calc(100% - 60px); 
}

.banner p {
  margin: 0;
  font-size: 18px;
  color: #333;
}




@media(max-width: 750px) {

    .sectionCenterItemList {
        padding-top: 50px;
    }
}